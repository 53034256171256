<template>
<div>
  <b-row class="mb-1">
    <b-col cols="12" md="8" class="mb-md-0 mb-2">
      <crud-button 
      :onClickList="fetchList"
      :onClickAddNew="addNew"
      />
    </b-col>
    <b-col cols="12" md="4" class="mb-md-0 mb-2">
      <b-form-input
        v-model="queryFilter"
        class="mr-1"
        :placeholder="$t('Ara...')" />
    </b-col>
  </b-row>
  
  <b-card
    no-body
    class="card-company-table"
  >
    <b-table
      :items="filteredList"
      responsive
      :fields="tableColumns"
      class="mb-0"
      show-empty
      empty-text="Hiçbir eşleşen kayıt bulunamadı"
    >
      <!-- durum -->
      <template #cell(isActive)="data">
        <div class="text-nowrap">
          <b-form-checkbox
            :checked="data.item.isActive"
            class="custom-control-success"
            name="check-button"
            @change="deactivate(data.item.sGuid)"
            >
            {{ $t('Aktif/Pasif') }}
          </b-form-checkbox>
        </div>
      </template>
      
      <template #cell(actions)="data">
        <div class="text-right">
          <!-- Update -->
          <feather-icon
            :id="`user-row-${data.item.id}-edit-icon`"
            @click="update(data.item)"
            icon="EditIcon"
            class="cursor-pointer text-primary"
            size="16"
          />

          <!-- Remove -->
          <feather-icon
            :id="`user-row-${data.item.id}-remove-icon`"
            @click="remove(data.item.sGuid)"
            icon="Trash2Icon"
            class="cursor-pointer mx-1 text-danger"
            size="16"
          />
        </div>
      </template>
    </b-table>
  </b-card>
  <save-kirma-planlari v-if="modalObject.showModal" :modalObject="modalObject" />
</div>
</template>
<script>
// Alert
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

// custom
import SaveKirmaPlanlari from './SaveKirmaPlanlari.vue';

export default {
  components: {
    SaveKirmaPlanlari,
  },
  data() {
    return {
      listData: [],
      queryFilter: null,
      tableColumns: [
        { label: 'Kodu', key: 'sKodu' },
        { label: 'Adı', key: 'sAdi' },
        { label: 'Standart', key: 'sStandartName' },
        { label: 'Durum', key: 'isActive' },
        { label: 'Aksiyonlar', key: 'actions', class: 'text-right' },
      ],
      modalObject: {
        item: {},
        showModal: false,
        onSuccess: this.fetchList,
      },
    }
  },
  computed: {
    filteredList() {
      return this.listData.filter(item => {
        if (this.queryFilter === null || this.queryFilter === '') {
          return true;
        }
        return item.sKodu.toLowerCase().includes(this.queryFilter.toLowerCase()) 
                || item.sAdi.toLowerCase().includes(this.queryFilter.toLowerCase())
                || item.sStandartName.toLowerCase().includes(this.queryFilter.toLowerCase());
      })
    },
  },
  methods: {
    update(item) {
      this.modalObject.item = {};
      Object.assign(this.modalObject.item, item);
      this.modalObject.showModal = true;
    },
    addNew() {
      this.modalObject.item = null;
      this.modalObject.showModal = true;
    },
    deactivate(guid) {
      ApiService.get(`manage/kalite/kirmaplani/activetoggle/${guid}`, {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });
            this.fetchList();
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
    remove(guid) {
      AlertService.confirmDelete(this, () => {
        ApiService.get(`manage/kalite/kirmaplani/delete/${guid}`, {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });
            this.fetchList();
          } else {
            AlertService.error(this, response.message)
          }
        });
      }, 'Silmek istediğinizden emin misiniz?');
    },

    fetchList() {
      ApiService.get('manage/kalite/kirmaplani/getall', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          this.listData = response.result;
        } else {
          AlertService.error(this, response.message)
        }
      });
    },
  },
  mounted() {
    this.fetchList();
  },
};

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
