<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-save-form-ref"
      title="Kırma Planı"
      ok-title="Kaydet"
      cancel-title="Kapat"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      no-enforce-focus
      centered
      size="xl"
      v-if="dataForm"
      @hidden="hiddenModal"
      @ok="handleOk"
    >
      <validation-observer ref="saveFormRef">
        <b-form ref="form" @submit.stop.prevent="saveForm">
          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group label="Kodu">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="sKodu"
                >
                  <b-form-input
                    v-model="dataForm.sKodu"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group label="Adı">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="sAdi"
                >
                  <b-form-input
                    v-model="dataForm.sAdi"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group label="Standart Adı">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="sStandartName"
                >
                  <b-form-input
                    v-model="dataForm.sStandartName"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group label="" label-for="contact-options" label-class="mb-1">
                <div class="demo-inline-spacing">
                  <b-form-checkbox
                    v-model="dataForm.isActive"
                    class="custom-control-primary"
                  >
                    {{ $t("Aktif Durumu") }}
                  </b-form-checkbox>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="12" class="mb-md-0 mb-2">
              <b-button @click="addNewDetail" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="warning">
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('Yeni Ekle') }}</span>
              </b-button>
              <b-table
                :items="kL_KirmaZamaniDetays"
                responsive
                :fields="tableColumns"
                class="mb-0"
                show-empty
                sticky-header
                empty-text="Hiçbir eşleşen kayıt bulunamadı"
              >
                <!-- sElekCesitleri -->
                <template #cell(ldZamanDegeri)="data">
                  <div class="text-nowrap">
                    <b-form-input
                      v-model="data.item.ldZamanDegeri"
                      type="number"
                      size="sm"
                    />
                  </div>
                </template>

                <!-- ldGecerlilikZamani -->
                <template #cell(sZaman)="data">
                  <div class="text-nowrap">
                    <b-form-select v-model="data.item.sZaman" :options="zamans" size="sm">

                    </b-form-select>
                  </div>
                </template>

                <!-- ldGecerlilikZamani -->
                <template #cell(ldGecerlilikZamani)="data">
                  <div class="text-nowrap">
                    <b-form-input
                      v-model="data.item.ldGecerlilikZamani"
                      type="number"
                      size="sm"
                    />
                  </div>
                </template>

                <!-- ldTolerans -->
                <template #cell(ldTolerans)="data">
                  <div class="text-nowrap">
                    <b-form-input
                      v-model="data.item.ldTolerans"
                      size="sm"
                    />
                  </div>
                </template>

                <!-- sRenk -->
                <template #cell(sRenk)="data">
                  <div class="text-nowrap">
                    <b-form-input
                      v-model="data.item.sRenk"
                      type="text"
                      size="sm"
                    />
                  </div>
                </template>
                <!-- sRenk -->
                <template #cell(aksiyon)="data">
                  <div class="text-nowrap">
                    <b-button @click="removeDetail(data.item)" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="danger">
                      <feather-icon
                        icon="DeleteIcon"
                        class="mr-50"
                      />
                    </b-button>
                  </div>
                </template>

              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    modalObject: {
      type: Object,
       default() {
        return {}
      },
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      required,
      dataForm: {
        sKodu: null,
        sAdi: null,
        sStandartName: null,
        isActive: true,
      },
      kL_KirmaZamaniDetays: [],
      kL_KirmaZamaniDelets: [],

      tableColumns: [
        { label: 'Zaman Değeri', key: 'ldZamanDegeri' },
        { label: 'Zaman', key: 'sZaman' },
        { label: 'GeçerlilikZaman', key: 'ldGecerlilikZamani' },
        { label: 'Tolerans', key: 'ldTolerans' },
        { label: 'Renk', key: 'sRenk' },
        { label: 'Aksiyon', key: 'aksiyon' },
      ],
      zamans: [ 
        { value: 'gün', text: 'Gün' }, { value: 'ay', text: 'Ay' },
      ],
    }
  },
  methods: {
    saveForm() {
      this.$refs.saveFormRef.validate().then(success => {
        if (success) {
          const query = {
            KL_KirmaPlani: this.dataForm,
            kL_KirmaZamaniDetays: this.kL_KirmaZamaniDetays,
            kL_KirmaZamaniDelets: this.kL_KirmaZamaniDelets,
          };

          ApiService.post('manage/kalite/kirmaplani/save', query, this)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              });
              this.modalObject.onSuccess();
              this.hiddenModal();
            } else {
              AlertService.error(this, response.message)
            }
          });
        }
      });
    },
    addNewDetail() {
      this.kL_KirmaZamaniDetays.push({
        ldZamanDegeri: 0,
        sZaman: 'gün',
        ldGecerlilikZamani: 0,
        ldTolerans: 0,
        sRenk: null,
      });
    },
    removeDetail(item) {
      const findRemovedItem = this.kL_KirmaZamaniDetays.find(x => x.id === item.id);
      
      if (findRemovedItem.id) {
        const newOBject = {};
        this.kL_KirmaZamaniDelets.push(Object.assign(newOBject, findRemovedItem));
      }
      this.kL_KirmaZamaniDetays.splice(this.kL_KirmaZamaniDetays.indexOf(findRemovedItem), 1);
    },

    initialModal() {
      if (this.modalObject.item) {
        this.dataForm = this.modalObject.item;

        ApiService.get(`manage/kalite/kirmazamani/get/${this.dataForm.id}`, {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            if (response.result.length) {
              this.kL_KirmaZamaniDetays = response.result;
            }
          } else {
            AlertService.error(this, response.message)
          }
        });
      }
      this.$refs['modal-save-form-ref'].show();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.saveForm()
    },
    hiddenModal() {
      this.$refs['modal-save-form-ref'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
